<template>
  <div>
    <grid-table
      v-if="rowData.length > 0"
      :column-defs="columnDefs"
      :row-data="rowData"
    >
      <template v-slot:cell-renderer>
        <ca-button :color="'danger'" :type="'ghost'">Cancel</ca-button>
      </template>
    </grid-table>
    <empty-dataset v-else></empty-dataset>
  </div>
</template>

<script>
import GridTable from "../../../components/tables/GridTable";
// import payment_history from "../db/payment_history";
import CaButton from "../../../components/buttons/CaButton";
import axios from "../../../../axios";
import gridTableParentMixin from "../../../../mixins/gridTableParentMixin";
import EmptyDataset from "../../../components/displays/EmptyDataset";
export default {
  mixins: [gridTableParentMixin],
  name: "PaymentHistory",
  components: { EmptyDataset, CaButton, GridTable },
  data() {
    return {
      columnDefs: [
        {
          headerName: "ID",
          field: "id",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Date",
          field: "created_at",
          type: "date",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Reference No",
          field: "transaction_id",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Payment Method",
          field: "payment_method",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Package",
          field: "package_name",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Amount",
          field: "amount",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Action",
          field: "actions",
          hasCellRendererFramework: true,
        },
      ],
      rowData: [],
      dataResourceUrl: `${this.$store.state.server.requestUri}/admin/user/${this.$route.params.id}/payment-history`,
    };
  },
  methods: {
    getTableData(resourceRul) {
      const loading = this.$vs.loading();
      axios
        .get(`${resourceRul}`)
        .then((res) => {
          this.rowData = res.data.data;
          console.log(res.data.data);
          loading.close();
        })
        .catch((err) => {
          console.log(err);
          loading.close();
        });
    },
  },
  mounted() {
    this.getTableData(`${this.dataResourceUrl}?${this.defaultQueryString}`);
  },
};
</script>

<style scoped></style>
